$(function(){
	if($('#gmap').length){
		var map;
		var MY_MAPTYPE_ID = 'custom_style';

		function initialize() {
			var markerPos = new google.maps.LatLng(0, 0);
			
			var featureOpts = [
			    {
			        "featureType": "all",
			        "elementType": "geometry.fill",
			        "stylers": [
			            {
			                "weight": "2.00"
			            }
			        ]
			    },
			    {
			        "featureType": "all",
			        "elementType": "geometry.stroke",
			        "stylers": [
			            {
			                "color": "#9d9d9d"
			            }
			        ]
			    },
			    {
			        "featureType": "all",
			        "elementType": "labels.text",
			        "stylers": [
			            {
			                "visibility": "simplified"
			            },
			            {
			                "color": "#515151"
			            }
			        ]
			    },
			    {
			        "featureType": "landscape",
			        "elementType": "all",
			        "stylers": [
			            {
			                "color": "#f0efed"
			            }
			        ]
			    },
			    {
			        "featureType": "landscape",
			        "elementType": "geometry.fill",
			        "stylers": [
			            {
			                "color": "#f0efed"
			            }
			        ]
			    },
			    {
			        "featureType": "landscape.man_made",
			        "elementType": "geometry.fill",
			        "stylers": [
			            {
			                "color": "#f0efed"
			            }
			        ]
			    },
			    {
			        "featureType": "landscape.man_made",
			        "elementType": "labels.text",
			        "stylers": [
			            {
			                "visibility": "simplified"
			            }
			        ]
			    },
			    {
			        "featureType": "poi",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "off"
			            }
			        ]
			    },
			    {
			        "featureType": "poi.business",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "on"
			            }
			        ]
			    },
			    {
			        "featureType": "poi.business",
			        "elementType": "labels.text",
			        "stylers": [
			            {
			                "visibility": "on"
			            }
			        ]
			    },
			    {
			        "featureType": "poi.business",
			        "elementType": "labels.icon",
			        "stylers": [
			            {
			                "visibility": "off"
			            },
			            {
			                "color": "#f1f030"
			            }
			        ]
			    },
			    {
			        "featureType": "road",
			        "elementType": "all",
			        "stylers": [
			            {
			                "saturation": -100
			            },
			            {
			                "lightness": 45
			            }
			        ]
			    },
			    {
			        "featureType": "road",
			        "elementType": "geometry.fill",
			        "stylers": [
			            {
			                "color": "#ffffff"
			            }
			        ]
			    },
			    {
			        "featureType": "road",
			        "elementType": "labels.text.fill",
			        "stylers": [
			            {
			                "color": "#5d5d5d"
			            }
			        ]
			    },
			    {
			        "featureType": "road",
			        "elementType": "labels.text.stroke",
			        "stylers": [
			            {
			                "color": "#ffffff"
			            }
			        ]
			    },
			    {
			        "featureType": "road.highway",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "on"
			            }
			        ]
			    },
			    {
			        "featureType": "road.highway",
			        "elementType": "geometry.fill",
			        "stylers": [
			            {
			                "visibility": "on"
			            },
			            {
			                "color": "#ffffff"
			            }
			        ]
			    },
			    {
			        "featureType": "road.highway",
			        "elementType": "geometry.stroke",
			        "stylers": [
			            {
			                "visibility": "simplified"
			            },
			            {
			                "weight": "3"
			            },
			            {
			                "color": "#ffffff"
			            }
			        ]
			    },
			    {
			        "featureType": "road.arterial",
			        "elementType": "labels.icon",
			        "stylers": [
			            {
			                "visibility": "off"
			            }
			        ]
			    },
			    {
			        "featureType": "transit",
			        "elementType": "all",
			        "stylers": [
			            {
			                "visibility": "off"
			            }
			        ]
			    },
			    {
			        "featureType": "water",
			        "elementType": "all",
			        "stylers": [
			            {
			                "color": "#ffffff"
			            },
			            {
			                "visibility": "on"
			            }
			        ]
			    },
			    {
			        "featureType": "water",
			        "elementType": "geometry.fill",
			        "stylers": [
			            {
			                "color": "#ffffff"
			            }
			        ]
			    },
			    {
			        "featureType": "water",
			        "elementType": "labels.text.fill",
			        "stylers": [
			            {
			                "color": "#070707"
			            }
			        ]
			    },
			    {
			        "featureType": "water",
			        "elementType": "labels.text.stroke",
			        "stylers": [
			            {
			                "color": "#ffffff"
			            }
			        ]
			    }
			];
			
			var mapOptions = {
				zoom: 15,
				scrollwheel : false,
				zoomControl: true,
				disableDefaultUI: true,
				center: markerPos,
				labelContent: 'SITE NAME',
				mapTypeControlOptions: {
				  mapTypeIds: [google.maps.MapTypeId.ROADMAP, MY_MAPTYPE_ID]
				},
				mapTypeId: MY_MAPTYPE_ID
			};// mappOptions
			
			map = new google.maps.Map(document.getElementById('gmap'), mapOptions);
			
			
			var styledMapOptions = {
				name: 'Custom Style'
			};//StyledMapOptions
			
			var customMapType = new google.maps.StyledMapType(featureOpts, styledMapOptions);
			
			map.mapTypes.set(MY_MAPTYPE_ID, customMapType);
			
			var adresses = [
				'103 5e rue de la Pointe, Shawinigan, QC G9N 1E2',
				'290 Rue Saint Joseph, La Tuque, QC G9X 3Z8',
			];
			
			var bounds = new google.maps.LatLngBounds();
			var infoWindows = [];
			$.each(adresses, function(index, val) {
				geocoder = new google.maps.Geocoder();
				geocoder.geocode( { 'address': val}, function(results, status) {
					if (status == google.maps.GeocoderStatus.OK) {
						var lat = results[0].geometry.location.lat();
						var lng = results[0].geometry.location.lng();
						
						
						var infowindow = new google.maps.InfoWindow({
							content: '<p><a href="https://www.google.ca/maps/dir//'+encodeURIComponent(val)+'" target="_blank">Voir l\'itinéraire</a></p>'
						});
						
						infoWindows.push(infowindow);
						
						var markerMap = new google.maps.Marker({
							position: new google.maps.LatLng(lat, lng),
							map: map,
							title: 'Rochette et Lahaie INC',
							icon: siteURI+'/images/marker.png'
						});// A marker
						
						bounds.extend(markerMap.getPosition());
						
						markerMap.addListener('click', function() {
							for (var i=0;i<infoWindows.length;i++) {
								infoWindows[i].close();
							}
							
							infowindow.open(map, markerMap);
						});
						
						map.fitBounds(bounds);
					}
				});
			});
			
		}// init

		google.maps.event.addDomListener(window, 'load', initialize);
	}
});